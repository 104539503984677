.error {
  color: var(--color-error);
}

.phone-input {
  margin-bottom: 10px;
}

.send-button {
  margin-top: 5px;
}
