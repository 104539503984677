body {
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-error: red;
}

.data-grid {
  width: 500px;
}

.data-editor {
  width: 100% !important;
  height: 100% !important;
}
.jsoneditor {
  margin-top: 30px;
  &-outer {
    height: 200px !important;
  }
}

.tabs {
  max-width: 1400px;
  margin: 0 auto !important;
}
